import { useEffect, useState } from 'react'
import { useAuthContext } from './useAuthContext'
import { auth } from '../firebase/config'
import { signOut } from 'firebase/auth'
import toast from 'react-hot-toast'

export const useLogout = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [loading, setLoading] = useState(false)
  const { dispatch } = useAuthContext()

  const logout = async () => {
    setLoading(true)

    try {
      // sign the user out
      await signOut(auth)

      // dispatch logout action
      dispatch({ type: 'LOGOUT' })

      // update state
      if (!isCancelled) {
        setLoading(false)
      }
    } catch (err) {
      if (!isCancelled) {
        toast.error(err.message)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { logout, loading }
}
