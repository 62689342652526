import { Fragment } from 'react'
import { HiMenuAlt2, HiOutlineLogout } from 'react-icons/hi'
import { Menu, Transition } from '@headlessui/react'
import { useLogout } from '../hooks/useLogout'
import useDarkMode from '../hooks/useDarkMode'
import { FaMoon, FaSun } from 'react-icons/fa'

export default function Topbar({ setSidebarOpen }) {
  return (
    <div className='sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white dark:bg-slate-500 shadow'>
      <button
        type='button'
        className='px-4 border-r border-gray-200 text-gray-500 dark:text-slate-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500 md:hidden'
        onClick={() => setSidebarOpen(true)}
      >
        <span className='sr-only'>Open sidebar</span>
        <HiMenuAlt2 className='h-6 w-6' aria-hidden='true' />
      </button>
      <div className='flex-1 px-4 flex justify-end'>
        <div className='ml-4 flex items-center md:ml-6'>
          {/* Profile dropdown */}
          <UserMenu />
        </div>
      </div>
    </div>
  )
}

function UserMenu() {
  const { logout } = useLogout()
  const [darkTheme, setDarkTheme] = useDarkMode()
  const handleMode = () => setDarkTheme(!darkTheme)

  return (
    <Menu as='div' className='ml-3 relative'>
      <div>
        <Menu.Button className='max-w-xs bg-white dark:bg-slate-800 flex items-center text-sm rounded-full focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-sky-500 dark:focus:ring-slate-400'>
          <span className='sr-only'>Open user menu</span>
          <img
            className='h-8 w-8 rounded-full'
            src='https://firebasestorage.googleapis.com/v0/b/seth-utilities.appspot.com/o/batman.jpg?alt=media&token=bd14bad1-07fa-44ab-8059-984e2d73eed5'
            alt=''
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg py-1 bg-white  ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <Menu.Item>
            <button
              onClick={handleMode}
              className='flex w-full items-center px-4 py-2 text-sm text-slate-700 hover:bg-slate-100'
            >
              {darkTheme ? (
                <FaSun className='h-4 w-4 text-slate-400 mr-1' />
              ) : (
                <FaMoon className='h-4 w-4 text-slate-400 mr-1' />
              )}
              Dark Theme
            </button>
          </Menu.Item>
          <Menu.Item>
            <button
              onClick={logout}
              className='nav-item w-full text-left flex items-center px-4 py-2 text-sm text-slate-700 hover:bg-slate-100'
            >
              <HiOutlineLogout
                className='h-4 w-4 text-slate-400 mr-1'
                aria-hidden='true'
              />{' '}
              Logout
            </button>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
