import { Fragment } from 'react'
import { HiX } from 'react-icons/hi'
import { AiOutlineDashboard } from 'react-icons/ai'
import { Dialog, Transition } from '@headlessui/react'
import { NavLink, Link } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext'
import { ReactComponent as Logo } from '../img/logo.svg'
import { FaRobot } from 'react-icons/fa'
import { BsFillBarChartLineFill } from 'react-icons/bs'

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const { token } = useAuthContext()

  const navigation = [
    { name: 'Dashboard', to: '/', icon: AiOutlineDashboard },
    { name: 'Call Bot', to: '/call-bot', icon: FaRobot },
    { name: 'Charts', to: '/charts', icon: BsFillBarChartLineFill },
  ]

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 flex z-40 md:hidden'
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div
              className={`relative flex-1 flex flex-col max-w-xs w-full bg-slate-700`}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 -mr-12 pt-2'>
                  <button
                    type='button'
                    className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <HiX className='h-6 w-6 text-white' aria-hidden='true' />
                  </button>
                </div>
              </Transition.Child>
              <div className='flex-1 h-0 pb-4 overflow-y-auto'>
                <Link
                  to='/'
                  className='flex flex-shrink-0 px-4 h-24 w-full py-5 bg-slate-600 dark:bg-slate-900'
                >
                  <Logo className='fill-white' />
                </Link>
                <nav className='mt-5 px-2 space-y-1'>
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.to}
                      className={({ isActive }) =>
                        isActive
                          ? `bg-slate-800 text-white hover:bg-slate-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md`
                          : `text-white hover:bg-slate-600 hover:bg-opacity-75 group flex items-center px-2 py-2 text-sm font-medium rounded-md`
                      }
                    >
                      <item.icon
                        className={`mr-4 flex-shrink-0 h-6 w-6 text-slate-300`}
                        aria-hidden='true'
                      />
                      {item.name}
                    </NavLink>
                  ))}
                </nav>
              </div>
              <div
                className={`flex-shrink-0 flex border-t border-slate-800 bg-slate-900 h-20 px-5 items-center text-sm`}
              >
                <div className='flex flex-col'>
                  <p className={`text-slate-100 text-xs`}>Logged in as:</p>
                  <p className='text-white font-bold'>{token.name}</p>
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className='flex-shrink-0 w-14' aria-hidden='true'>
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className='hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0'>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className='flex-1 flex flex-col min-h-0 bg-slate-700 dark:bg-slate-800'>
          <div className='flex-1 h-0 pb-4 overflow-y-auto'>
            <Link
              to='/'
              className='flex flex-shrink-0 px-4 h-24 w-full py-5 bg-slate-600 dark:bg-slate-900'
            >
              <Logo className='fill-white' />
            </Link>
            <nav className='mt-5 flex-1 px-2 space-y-1'>
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.to}
                  className={({ isActive }) =>
                    isActive
                      ? `bg-slate-800 dark:bg-slate-900 text-white  group flex items-center px-2 py-2 text-sm font-medium rounded-md`
                      : `text-white hover:bg-slate-600 hover:bg-opacity-75 group flex items-center px-2 py-2 text-sm font-medium rounded-md`
                  }
                >
                  <item.icon
                    className={`mr-3 flex-shrink-0 h-6 w-6 text-slate-300`}
                    aria-hidden='true'
                  />
                  {item.name}
                </NavLink>
              ))}
            </nav>
          </div>
          <div
            className={`flex-shrink-0 flex border-t border-slate-800 bg-slate-600 dark:bg-slate-900 h-20 px-5 items-center text-sm`}
          >
            <div className='flex flex-col'>
              <p className={`text-slate-100 text-xs`}>Logged in as:</p>
              <p className='text-white font-bold'>{token.name}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
