import { createContext, useReducer, useEffect } from 'react'
import { auth } from '../firebase/config'
import { onAuthStateChanged } from 'firebase/auth'

export const AuthContext = createContext()

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      }
    case 'LOGOUT':
      return { ...state, user: null, token: null }
    case 'AUTH_IS_READY':
      return {
        authIsReady: true,
        user: action.payload.user,
        token: action.payload.token,
      }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    token: null,
    authIsReady: false,
  })

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      let userInfo = { user, token: null }
      if (user) {
        user.getIdTokenResult().then((token) => {
          userInfo = { user, token: token.claims }
          dispatch({ type: 'AUTH_IS_READY', payload: userInfo })
        })
      } else {
        dispatch({ type: 'AUTH_IS_READY', payload: userInfo })
      }
    })

    return () => unsubscribe()
  }, [])

  // console.log('AuthContext:', state)

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
}
