import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { lazy, Suspense, useState } from 'react'
import PrivateRoute from './routes/PrivateRoute'
import PublicRoute from './routes/PublicRoute'
import Sidebar from './components/Sidebar'
import Topbar from './components/Topbar'
import Loader from './components/Loader'
import { useAuthContext } from './hooks/useAuthContext'

const Home = lazy(() => import('./pages/Home'))
const CallBot = lazy(() => import('./pages/CallBot'))
const Charts = lazy(() => import('./pages/Charts'))
const ChartDetails = lazy(() => import('./pages/ChartDetails'))
const Empty = lazy(() => import('./components/Empty'))
const Login = lazy(() => import('./pages/Login'))

export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { authIsReady, user } = useAuthContext()

  return (
    <>
      {authIsReady && (
        <Router>
          {user && (
            <Sidebar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
          )}
          <div className={`flex flex-col min-h-screen  ${user && 'md:pl-64'}`}>
            {user && <Topbar setSidebarOpen={setSidebarOpen} />}
            <main className='flex flex-col grow bg-slate-100 dark:bg-slate-700 dark:text-slate-100'>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route path='/login' element={<PublicRoute />}>
                    <Route path='/login' element={<Login />} />
                  </Route>
                  <Route path='/' element={<PrivateRoute />}>
                    <Route path='/' element={<Home />} />
                    <Route path='/call-bot' element={<CallBot />} />
                    <Route path='/charts' element={<Charts />} />
                    <Route path='/charts/:habitId' element={<ChartDetails />} />
                    <Route path='*' element={<Empty />} />
                  </Route>
                </Routes>
              </Suspense>
            </main>
          </div>
        </Router>
      )}
      <Toaster />
    </>
  )
}
