import { initializeApp } from 'firebase/app'
import { getAuth, OAuthProvider } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

const firebaseConfig = {
  apiKey: 'AIzaSyANhHG_jfkGyyhX__NvmYzgOG6SDTHPluU',
  authDomain: 'seth-utilities.firebaseapp.com',
  projectId: 'seth-utilities',
  storageBucket: 'seth-utilities.appspot.com',
  messagingSenderId: '390110080766',
  appId: '1:390110080766:web:0dced2e0b8300e3063bfaf',
}

const app = initializeApp(firebaseConfig)

window.self.FIREBASE_APPCHECK_DEBUG_TOKEN =
  process.env.REACT_APP_FIREBASE_DEBUG_TOKEN

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
})

const auth = getAuth(app)
const provider = new OAuthProvider('microsoft.com')
const db = getFirestore(app)
const functions = getFunctions(app)
const videoBucket = getStorage(
  app,
  `gs://${process.env.REACT_APP_FIREBASE_VIDEO_BUCKET}`
)
const imageBucket = getStorage(
  app,
  `gs://${process.env.REACT_APP_FIREBASE_IMAGES_BUCKET}`
)
const profileBucket = getStorage(
  app,
  `gs://${process.env.REACT_APP_FIREBASE_PROFILE_IMG_BUCKET}`
)

export {
  auth,
  provider,
  functions,
  db,
  videoBucket,
  imageBucket,
  profileBucket,
  appCheck,
}
