export default function Loader({ white }) {
  return (
    <div className='flex flex-col grow items-center justify-center'>
      <div className='spinner '>
        <div
          className={`bounce1 ${
            white ? 'bg-white' : 'bg-sky-700 dark:bg-white'
          }`}
        ></div>
        <div
          className={`bounce2 ${
            white ? 'bg-white' : 'bg-sky-700 dark:bg-white'
          }`}
        ></div>
        <div
          className={`bounce3 ${
            white ? 'bg-white' : 'bg-sky-700 dark:bg-white'
          }`}
        ></div>
      </div>
      <span className='sr-only'>Loading...</span>
    </div>
  )
}
